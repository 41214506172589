<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ดูภาพรวมของระบบตามวัน เวลา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group">
              <label for="username">เลือกธนาคาร</label>
              <b-form-select
                v-model="search_from"
                :options="fromOPs"
                placeholder="เลือกธนาคาร"
              />
            </div>
          </div> -->
          <div class="col-md-2">
            <label for="dateEnd" />
            <button
              class="btn btn-gradient-dark btn-block"
              style="margin-top: 3px"
              @click="getData()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <b-row class="match-height">
          <b-col
            md="12"
            cols="12"
          >
            <h4><i class="far fa-money-check-edit-alt" /> ที่มาของโบนัส</h4>
          </b-col>
          <!-- {{ DataShow }} -->
          <b-col
            v-for="(item , index) in DataShow"
            :key="index"
            md="3"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-total white">
                <div class="p-1">
                  <span>
                    <img
                      src="/images/bonus.jpg"
                      alt="bank"
                      class="bank-icon"
                    > {{ index }}
                  </span>
                  <h5 class="text-white">
                    รวม {{ Commas(item.reduce((prev, curr) => prev + Number(curr.bonus), 0)) }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>-</small>
                </div>
              </div>

            </div>
          </b-col>

          <!-- <b-col
            md="12"
            cols="12"
            class="mb-1"
          >
            <div>
              <div class="card-cradit-1 white">
                <div class="p-1">
                  <span>
                    <img
                      src="/bankIcons/bank.png"
                      alt="bank"
                      class="bank-icon"
                    > สรุปยอด
                  </span>
                  <h5 class="text-white">
                    ยอดรวมโบนัส {{ 0 }} บาท
                  </h5>
                </div>
                <div class="card-b d-flex justify-content-between">
                  <small>-</small>
                </div>
              </div>

            </div>
          </b-col> -->
        </b-row>
      </div>
    </div>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
import moment from 'moment-timezone'
// import flatPickr from 'vue-flatpickr-component'
import {
  BRow,
  BCol,
  VBTooltip,
  // BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BFormInput, BButton, BCardBody,
  VBToggle, BOverlay, BIconController,
  // BBadge, BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    // vSelect,
    // BModal,
    // BCard,
    // BTable,
    // BAvatar,
    // BFormGroup,
    // BFormSelect,
    // BPagination,
    // BFormInput,
    // BButton,
    // BCardBody,
    BOverlay,
    BIconController,
    // BBadge,
    // BFormRadio,
    // flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      search_type: null,
      search_val: null,
      show: false,
      show2: false,
      Selected: null,
      member: '',
      amount: '',
      optionsMember: '',
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      usersList: [],
      datasidebar: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Bangkok').format('YYYY-MM-DD'),
      DataShow: {
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      // this.getData()
    }, 60000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    this.getData()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    getData() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_val: this.search_val,
        search_type: this.search_type,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .post('/dashboard/Bonus', params)
        .then(response => {
          this.show = false
          this.DataShow = response.data
          // console.log(response.data)
        })
        .catch(error => console.log(error))
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style scoped>
  .bank-icon {
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
  }
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
